import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  AvatarLarge,
  AvatarMedium,
} from '../../../../components';

import css from './TopbarDesktop.module.css';
import IconCollection from '../../../../components/IconCollection/IconCollection';

const SignupLink = () => {
  return (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.signup1" />
      </span>
    </NamedLink>
  );
};

const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.login1" />
      </span>
    </NamedLink>
  );
};

const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <NamedLink
      className={classNames(css.navLink)}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const ProfileMenu = ({
  notificationCount,
  currentUserHasListings,
  currentPage,
  userRole,
  currentUser,
  onLogout,
  showYourProfileLink,
  showYourListingsLink,
  showProfileSettingsLink,
  listingSlug,
  listingId,
}) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;


  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem className={css.menuTopContent} key="ListingPage">
          <AvatarMedium className={css.avatarTopContent} user={currentUser} disableProfileLink />
          <h4 className={css.nameText}>{currentUser?.attributes?.profile?.displayName}</h4>
        </MenuItem>
        {showYourProfileLink ? (
          <MenuItem key="ListingPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ListingPage"
              params={{ slug: listingSlug, id: listingId }}
            >
                 <IconCollection name="listingIcon"/>
              Your Profile
            </NamedLink>
          </MenuItem>
        ) : null}
        {showYourListingsLink ? (
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <IconCollection name="listingIcon"/>
              {/* <span className={css.menuItemBorder} /> */}
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
        ) : null}
        {showProfileSettingsLink ? (
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
                  <IconCollection name="accountSettingIcon" />
              {/* <span className={css.menuItemBorder} /> */}
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
        ) : null}
        {userRole === 'organization' ? (
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
                 <IconCollection name="listingIcon"/>
              {/* <span className={css.menuItemBorder} /> */}
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
        ) : null}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <IconCollection name="accountSettingIcon" />

            {/* <span className={css.menuItemBorder} /> */}
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            {/* <span className={css.menuItemBorder} /> */}
            <IconCollection name="logoutIcon" />

            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    showCreateListingLink,
    createListingLinkText,
    showProfileSettingsLink,
    location,
    showYourProfileLink,
    listingSlug,
    listingId,
    onSearchSubmit,
    initialSearchFormValues,
    showYourListingsLink,
    exploreLinkParams,
  } = props;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className);

  const homeLink = (
    <NamedLink name="LandingPage" className={classNames(css.navLink)}>
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.home" />
      </span>
    </NamedLink>
  );
  const exploreLink = (
    <NamedLink
      name="SearchPage"
      params={{ ...exploreLinkParams }}
      className={classNames(css.navLink)}
    >
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.explore" />
      </span>
    </NamedLink>
  );
  const aboutLink = (
    <NamedLink name="NotFoundPage" className={classNames(css.navLink)}>
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.about" />
      </span>
    </NamedLink>
  );
  const contactLink = (
    <NamedLink name="NotFoundPage" className={classNames(css.navLink)}>
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.contact" />
      </span>
    </NamedLink>
  );
  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
      currentPage={currentPage}
      currentUser={currentUser}
      onLogout={onLogout}
      showYourProfileLink={showYourProfileLink}
      showYourListingsLink={showYourListingsLink}
      listingSlug={listingSlug}
      showProfileSettingsLink={showProfileSettingsLink}
      listingId={listingId}
    />
  ) : null;

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;

  return (
    <nav>
      {/* <div className={css.topWhiteBlack}></div> */}
      <div className={classes}>
        <LinkedLogo
          className={css.logoLink}
          layout="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
          linkToExternalSite={config?.topbar?.logoLink}
        />
        <div className={css.menuWrapper}>
          {homeLink}
          {exploreLink}
          {aboutLink}
          {contactLink}

          {showCreateListingLink ? (
            <NamedLink
              name="NewListingPage"
              className={classNames(
                css.navLink,
                location.pathname.includes('/l/') && css.menuActiveLink
              )}
            >
              <span className={css.linkText}>{createListingLinkText}</span>
            </NamedLink>
          ) : null}
          {inboxLinkMaybe}
          {profileMenuMaybe}
          {loginLinkMaybe}
          {signupLinkMaybe}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
  exploreLinkParams: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
